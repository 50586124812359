import { PageMemberPreview } from '@mero/api-sdk/dist/pages';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  Avatar,
  colors,
  H1,
  HSpacer,
  Icon,
  SmallBody,
  Spacer,
  styles as meroStyles,
  sizes,
  SafeAreaView,
  Row,
  Body,
  Column,
  useToast,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, ScrollView, Platform, Linking, View } from 'react-native';
import Svg, { Circle, G, Path, Rect, SvgProps } from 'react-native-svg';

import { HelpCenterScreenUrl } from './screens/HelpCenterScreen';
import { StarIcon } from './screens/PageReviewsScreen/PageReviewsScreen';

import { MenuIcon } from '../../../components/MenuItem';
import MenuItemGroup from '../../../components/MenuItemGroup';
import ProfileMenuMobile from '../../../components/ProfileMenu/ProfileMenuMobile';
import ProfileMenuWeb from '../../../components/ProfileMenu/ProfileMenuWeb';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useClickOutsideWeb } from '../../../hooks/useClickOutsideWeb';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { AppStorage } from '../../../app-storage';
import config from '../../../config';
import { AppContext } from '../../../contexts/AppContext';
import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessContext, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { IntercomContext } from '../../../contexts/IntercomContext';
import { backNavigationContext } from '../../../models/back-navigation';
import {
  AuthorizedStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  MenuTabStackParamList,
  RootStackParamList,
} from '../../../types';
import log from '../../../utils/log';
import { nameGenerator } from '../../../utils/string';
import { styles } from './styles';

const CloseIcon = () => (
  <Svg width={24} height={24}>
    <Path fill="none" d="M0 0h24v24H0z" />
    <Path
      d="m6.376 16.68 4.62-4.619-4.62-4.62a.754.754 0 1 1 1.066-1.066l4.62 4.62 4.62-4.62a.754.754 0 0 1 1.065 1.066l-4.62 4.62 4.62 4.619a.754.754 0 0 1-1.066 1.066l-4.62-4.619-4.619 4.62a.754.754 0 1 1-1.065-1.066Z"
      fill="#fff"
    />
  </Svg>
);

const DownArrowSmallIcon = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" {...props}>
    <G transform="translate(5844 -2177) rotate(90)">
      <Path d="M5.538,8.807,4.5,7.769,7.927,4.4,4.5,1.038,5.538,0,10,4.4Z" transform="translate(2178.517 5830.5)" />
      <Rect width={18} height={18} transform="translate(2177 5826)" fill="none" />
    </G>
  </Svg>
);

const DownArrowMediumIcon = () => (
  <Svg width={24} height={24} viewBox="0 0 24 24">
    <G data-name="Group 8611" transform="translate(5850 -2177) rotate(90)">
      <Path
        d="M5.885,11.743,4.5,10.358,9.069,5.869,4.5,1.384,5.885,0l5.954,5.869Z"
        transform="translate(2180.522 5832)"
      />
      <Rect width={24} height={24} transform="translate(2177 5826)" fill="none" />
    </G>
  </Svg>
);

const StarSmallIcon = () => (
  <Svg width="10.833" height="10" viewBox="0 0 10.833 10">
    <Path
      id="star"
      d="M10.817,4.159a.312.312,0,0,0-.253-.2L7.2,3.491,5.7.523a.344.344,0,0,0-.575,0l-1.5,2.968L.269,3.963a.312.312,0,0,0-.253.2.278.278,0,0,0,.074.3l2.448,2.3-.574,3.27a.284.284,0,0,0,.13.287.338.338,0,0,0,.336.017L5.416,8.806,8.4,10.331a.339.339,0,0,0,.336-.017.284.284,0,0,0,.13-.287l-.574-3.27,2.448-2.3A.278.278,0,0,0,10.817,4.159Z"
      transform="translate(0 -0.368)"
      fill="#ffb900"
    />
  </Svg>
);

const WarningIcon = () => (
  <Svg width={24} height={24} viewBox="0 0 24 24">
    <G data-name="Group 8760">
      <G
        transform="translate(-177 -281) translate(176 276.964)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      >
        <Path d="M13 8L4 24.071h18zM13 18.561v-4.335m-.482 5.961" />
        <Circle cx={0.453} cy={0.453} r={0.453} transform="translate(12.547 20.536)" />
      </G>
      <G data-name="Group 8760">
        <Path transform="translate(-177 -281) translate(163 260) translate(14 21)" fill="none" d="M0 0H24V24H0z" />
      </G>
    </G>
  </Svg>
);

const OrionEyeIcon = () => (
  <Svg width={24} height={24} viewBox="0 0 24 24">
    <G data-name="Group 5923">
      <Path data-name="Rectangle 62" fill="none" d="M0 0H24V24H0z" />
    </G>
    <G
      transform="translate(.5 -7.839)"
      fill="none"
      stroke="#202020"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
    >
      <Path d="M11.5 14.5c-5.247 0-9 5.339-9 5.339s3.753 5.339 9 5.339 9-5.339 9-5.339-3.753-5.339-9-5.339z" />
      <Circle cx={3.051} cy={3.051} r={3.051} transform="translate(8.449 16.636)" />
      <Path data-name="layer1" d="M10.433 19.076a1.22 1.22 0 001.072 1.831" />
    </G>
  </Svg>
);

const QRCodeIcon = () => (
  <Svg width={24} height={24} viewBox="0 0 24 24">
    <G data-name="Group 8603" transform="translate(-14 -21)">
      <Path
        d="M80.9-875.5a.871.871 0 01-.641-.259.871.871 0 01-.259-.641v-2.7a.871.871 0 01.259-.641.871.871 0 01.641-.259h2.7a.871.871 0 01.641.259.871.871 0 01.259.641.871.871 0 01-.259.641.871.871 0 01-.641.259h-1.8v1.8a.871.871 0 01-.259.641.871.871 0 01-.641.259zm0 13.5a.871.871 0 01-.641-.259.871.871 0 01-.259-.641v-2.7a.871.871 0 01.259-.641.871.871 0 01.641-.259.871.871 0 01.641.259.871.871 0 01.259.641v1.8h1.8a.871.871 0 01.641.259.871.871 0 01.259.641.871.871 0 01-.259.641.871.871 0 01-.641.259zm13.5 0a.871.871 0 01-.641-.259.871.871 0 01-.259-.641.871.871 0 01.259-.641.871.871 0 01.641-.259h1.8v-1.8a.871.871 0 01.259-.641.871.871 0 01.641-.259.871.871 0 01.641.259.871.871 0 01.259.641v2.7a.871.871 0 01-.259.641.871.871 0 01-.641.259zm2.7-13.5a.871.871 0 01-.641-.259.871.871 0 01-.259-.641v-1.8h-1.8a.871.871 0 01-.641-.259.871.871 0 01-.259-.641.871.871 0 01.259-.641.871.871 0 01.641-.259h2.7a.871.871 0 01.641.259.871.871 0 01.259.641v2.7a.871.871 0 01-.259.641.871.871 0 01-.641.259zm-3.15 10.8v-1.35h1.35v1.35zm0-2.7v-1.35h1.35v1.35zm-1.35 1.35v-1.35h1.35v1.35zm-1.35 1.35v-1.35h1.35v1.35zm-1.35-1.35v-1.35h1.35v1.35zm2.7-2.7v-1.35h1.35v1.35zm-1.35 1.35v-1.35h1.35v1.35zm-1.35-1.35v-1.35h1.35v1.35zm.9-3.15a.871.871 0 01-.641-.259.871.871 0 01-.259-.641v-3.6a.871.871 0 01.259-.641.871.871 0 01.641-.259h3.6a.871.871 0 01.641.259.871.871 0 01.259.641v3.6a.871.871 0 01-.259.641.871.871 0 01-.641.259zm-7.2 7.2a.871.871 0 01-.641-.259.871.871 0 01-.259-.641v-3.6a.871.871 0 01.259-.641.871.871 0 01.641-.259h3.6a.871.871 0 01.641.259.871.871 0 01.259.641v3.6a.871.871 0 01-.259.641.871.871 0 01-.641.259zm0-7.2a.871.871 0 01-.641-.259.871.871 0 01-.259-.641v-3.6a.871.871 0 01.259-.641.871.871 0 01.641-.259h3.6a.871.871 0 01.641.259.871.871 0 01.259.641v3.6a.871.871 0 01-.259.641.871.871 0 01-.641.259zm.45 5.85h2.7v-2.7h-2.7zm0-7.2h2.7v-2.7h-2.7zm7.2 0h2.7v-2.7h-2.7z"
        transform="translate(-63 904)"
      />
      <Path data-name="Rectangle 2878" transform="translate(14 21)" fill="none" d="M0 0H24V24H0z" />
    </G>
  </Svg>
);

const ProCheckoutMenuIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G fill="none" data-name="Group 5932">
      <Path d="M0 0h32v32H0z" data-name="Rectangle 59" />
      <G data-name="Group 7613">
        <Path d="M4 4h24v24H4z" data-name="Rectangle 2767" />
        <G data-name="Group 7995">
          <G stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5}>
            <Path d="M12.416 16.441h4.1m-4.1 3.189h3.075m3.076-3.194h1.024m-1.026 3.194h1.024M8.174 8v16.4l1.953-1.279 1.98 1.283 1.952-1.283 1.959 1.279 1.952-1.279 1.959 1.279 1.953-1.279L23.84 24.4V8m-3.911 16.4h0Zm-3.911 0h0ZM7 8h18" />
            <Path d="m13.732 12.676 1.634 1.634 2.909-2.906" data-name="layer1" />
          </G>
          <Path d="M6 6h20v20H6z" data-name="Rectangle 2701" />
        </G>
      </G>
    </G>
  </Svg>
);

const ProMembershipMenuIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G fill="none" transform="translate(0 -.5)">
      <Circle cx={16} cy={16} r={16} transform="translate(0 .5)" />
      <Path d="M4 4.5h24v24H4Z" data-name="Path 8275" />
      <G
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.7}
        transform="translate(6 9.167)"
      >
        <Path d="M16.667 0v.333a1.667 1.667 0 1 1-3.333 0V0H6.667v.333a1.667 1.667 0 0 1-3.333 0V0H0v14.667h20V0ZM12 5.333h5.333M12 8h5.333M12 10.666h2.667" />
        <Circle cx={1.807} cy={1.807} r={1.807} data-name="layer1" transform="translate(4.219 4.335)" />
        <Path d="M6 8a3.367 3.367 0 0 0-3.332 3.433V12h6.667v-.567A3.367 3.367 0 0 0 6 8Z" data-name="layer1" />
      </G>
    </G>
  </Svg>
);

const ProductsMenuIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G transform="translate(0 -.5)">
      <Circle cx={16} cy={16} r={16} fill="none" transform="translate(0 .5)" />
      <G data-name="Group 7272">
        <Path fill="none" d="M0 .5h32v32H0z" data-name="Rectangle 59" />
        <G data-name="Group 5923">
          <Path fill="none" d="M4 4.5h24v24H4z" data-name="Rectangle 62" />
        </G>
        <G
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.7}
        >
          <Path d="m26.001 10.934-10-4.433-10 4.433v11.132l10 4.435 10-4.433Z" />
          <Path d="m6.001 10.934 10 4.433 10-4.433M11.467 8.501l10 4.433m-5.466 2.433v11.134" data-name="layer2" />
          <Path d="m23.333 16.367-4 1.766v3.766l4-1.766Z" />
        </G>
      </G>
    </G>
  </Svg>
);

type MenuScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<MenuTabStackParamList, 'MenuScreen'>,
  CompositeNavigationProp<
    BottomTabNavigationProp<HomeTabsParamsList, 'MenuTab'>,
    CompositeNavigationProp<
      DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
      CompositeNavigationProp<
        StackNavigationProp<AuthorizedStackParamList, 'Home'>,
        StackNavigationProp<RootStackParamList, 'Authorized'>
      >
    >
  >
>;

type Props = {
  navigation: MenuScreenNavigationProp;
} & CurrentBusinessProps &
  AuthorizedProps;

const MenuScreenComponent: React.FC<Props> = ({ navigation, page, authorization }: Props) => {
  const { t } = useTranslation('settings');
  const [intercomState, { openChat }] = IntercomContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();
  const unreadIntercomCount = intercomState.type === 'Ready' ? intercomState.unreadConversationCount : 0;
  const [appState] = AppContext.useContext();
  const { isPhone, isDesktop } = useMediaQueries();
  const toast = useToast();

  const [isPendingDelete, setIsPendingDelete] = React.useState(false);
  const isFocused = useIsFocused();
  const [showProfileWebMenu, setShowProfileWebMenu] = React.useState(false);
  const [showProfileMobileMenu, setShowProfileMobileMenu] = React.useState(false);

  const profileButtonsRef = React.useRef<View>(null);
  const hasEnoughReviews = page.details.feedback.score !== 0 && page.details.feedback.total !== 0;

  React.useEffect(() => {
    if (isFocused) {
      AppStorage.getPagesToDelete()
        .then((pages) => {
          setIsPendingDelete(pages.includes(page.details._id));
        })
        .catch((e) => {
          log.error('Failed to get pages pending deletion', e);
        });
    }
  }, [isFocused]);

  const user = authorization.user;

  const selfWorker: SavedWorker | undefined = React.useMemo(
    () => page.workers.find((w) => w.user._id === user._id),
    [user._id, page.workers],
  );

  const selfMember: PageMemberPreview | undefined = React.useMemo(
    () => page.members.find((w) => w.user._id === user._id),
    [user._id, page.workers],
  );

  const profilePhoto = selfMember?.user.profile.photo; // DO NOT fallback to user profile photo as it may confuse PROs (?)

  const selfWorkerId = selfWorker?._id;
  const selfUserId = selfWorker?.user._id;

  // User is an worker can manage only own worker profile
  const isWorker = !page.permissions.proProfiles.canManageAllProfiles() && selfWorker !== undefined;

  const clearPendingDelete = React.useCallback(() => {
    AppStorage.removePageToDelete(page.details._id)
      .then(() => {
        setIsPendingDelete(false);
      })
      .catch((e) => {
        log.error('Failed to remove page from pending deletion', e);
      });
  }, [pageState]);

  const navigateMarketingSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'MarketingSettingsScreen',
    });
  }, [navigation]);

  const navigatePageProfileSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'LocationProfileScreen',
    });
  }, [navigation]);

  const navigatePageOnlineProfileSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'LocationProfileScreen',
      params: {
        screen: 'OnlineLink',
      },
    });
  }, [navigation]);

  const navigateWorkerProfileSettings = React.useCallback(() => {
    if (selfWorkerId && selfUserId) {
      navigation.navigate('Authorized', {
        screen: 'Menu',
        params: {
          screen: 'WorkerProfileSettingsScreen',
          params: {
            workerId: selfWorkerId,
            userId: selfUserId,
          },
        },
      });
    }
  }, [navigation, selfWorkerId]);

  const navigateWorkerServicesSettings = React.useCallback(() => {
    if (selfWorkerId) {
      navigation.navigate('Authorized', {
        screen: 'Menu',
        params: {
          screen: 'WorkerServicesSettingsScreen',
          params: {
            screen: 'WorkerServices',
            params: {
              workerId: selfWorkerId,
            },
          },
        },
      });
    }
  }, [navigation, selfWorkerId]);

  const navigateWorkerScheduleSettings = React.useCallback(() => {
    if (selfWorkerId) {
      navigation.navigate('Authorized', {
        screen: 'Menu',
        params: {
          screen: 'WorkerScheduleSettingsScreen',
          params: {
            workerId: selfWorkerId,
          },
        },
      });
    }
  }, [navigation, selfWorkerId]);

  const navigateWorkerCalendarSettings = React.useCallback(() => {
    if (selfWorkerId && selfUserId) {
      navigation.navigate('Authorized', {
        screen: 'Menu',
        params: {
          screen: 'WorkerCalendarSettingsScreen',
          params: {
            workerId: selfWorkerId,
            userId: selfUserId,
          },
        },
      });
    }
  }, [navigation, selfWorkerId]);

  const navigatePageServicesSettings = React.useCallback(() => {
    if (isDesktop) {
      navigation.navigate('ServicesTab', {
        screen: 'ServicesWeb',
      });
    } else {
      navigation.navigate('ServicesTab', {
        screen: 'ServicesMobile',
      });
    }
  }, [navigation]);

  const navigateProsDashboard = React.useCallback(() => {
    if (isDesktop) {
      navigation.navigate('ProsTab', { screen: 'ProsWeb' });
    } else {
      navigation.navigate('ProsTab', { screen: 'ProsMobile' });
    }
  }, [navigation]);

  const navigatePageSubscriptionSettings = React.useCallback(() => {
    // if (Platform.OS === 'web') {
    //   // payu form submit doesn't work inside IOS iframe
    //   // so we need to open this route in a separate tab in web build
    //   // https://github.com/react-native-webview/react-native-webview/issues/666
    //   // https://github.com/react-native-webview/react-native-webview/issues/957
    //   // https://github.com/react-native-webview/react-native-webview/pull/2258
    //   Linking.openURL(
    //     `${config.proUrl}/drawer/page/subscription?pageId=${page.details._id}&hideLayout=true&nvb=${authorization.grant.accessToken}`,
    //   );
    // } else {
    navigation.navigate('Menu', {
      screen: 'PageSubscriptionSettingsScreen',
    });
    // }
  }, [navigation, page, authorization]);

  const navigatePageCheckoutSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PageCheckoutSettingsScreen',
    });
  }, [navigation]);

  const navigatePageMembershipSettingsScreen = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PageMembershipSettingsScreen',
    });
  }, [navigation]);

  const navigatePageReports = React.useCallback(() => {
    if (isPhone) {
      navigation.navigate('Menu', {
        screen: 'PageReportsMobileScreen',
      });
    } else {
      navigation.navigate('PageReportsScreen');
    }
  }, [navigation]);

  const navigatePageReviews = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PageReviewsScreen',
      params: {
        screen: 'Reviews',
      },
    });
  }, [navigation]);

  const navigatePageOnlinePaymentsSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PageOnlinePaymentsSettingsScreen',
    });
  }, [navigation]);

  const navigatePageGiftcardsSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PageGiftCardsSettingsScreen',
    });
  }, [navigation]);

  const navigatePageShop = React.useCallback(() => {
    navigation.navigate('Menu', { screen: 'ShopScreen' });
  }, [navigation]);

  const navigateSharePageProfile = React.useCallback(() => {
    navigation.navigate('Menu', { screen: 'SharePageProfileScreen' });
  }, [navigation]);

  const navigatePageNotificationsSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PageNotificationsSettingsScreen',
    });
  }, [navigation]);

  const navigateNotificationsOptions = React.useCallback(() => {
    navigation.navigate('Authorized', {
      screen: 'NotificationsOptionsScreen',
    });
  }, [navigation]);

  const navigateSelectPage = React.useCallback(() => {
    navigation.navigate('Authorized', {
      screen: 'SelectAccount',
    });
  }, [navigation]);

  const navigateProductDashboard = React.useCallback(() => {
    navigation.navigate('ProductsTab', {
      screen: 'ProductsDashboardScreen',
    });
  }, [navigation]);

  const navigateProductMenu = React.useCallback(() => {
    navigation.navigate('ProductsTab', {
      screen: 'ProductsMenuScreen',
    });
  }, [navigation]);

  const navigateContactSupport = React.useCallback(() => {
    openChat();
  }, [openChat]);

  useClickOutsideWeb({
    ref: profileButtonsRef,
    isVisible: showProfileWebMenu,
    onClickOutside() {
      setShowProfileWebMenu(false);
    },
    condition: isDesktop && Platform.OS === 'web',
  });

  const navigateHelpCenter = React.useCallback(() => {
    if (Platform.OS === 'web') {
      Linking.openURL(HelpCenterScreenUrl).catch(log.exception);
    } else {
      navigation.navigate('Menu', {
        screen: 'HelpCenterScreen',
      });
    }
  }, [navigation]);

  const openPageUrl = React.useCallback(async () => {
    try {
      const pageUrl = `${config.marketPlaceUrl}/p/${page.details.slug}`;
      await Linking.openURL(pageUrl);
    } catch {}
  }, [config, page]);

  const generalSetting = [
    {
      title: t('profileDetails'),
      icon: 'pro-menu-profile-details' as MenuIcon,
      onPress: navigateWorkerProfileSettings,
      show: isWorker && page.permissions.proProfiles.canEditOwnProfileDetails(),
    },
    {
      title: t('services'),
      icon: 'pro-menu-services' as MenuIcon,
      onPress: navigatePageServicesSettings,
      show: page.permissions.page.canManageServices(),
    },
    {
      title: t('calendarSettings'),
      icon: 'pro-menu-calendar-settings' as MenuIcon,
      onPress: navigateWorkerCalendarSettings,
      show: isWorker && page.permissions.proProfiles.canEditOwnProfile(),
    },
    {
      title: t('schedule'),
      icon: 'pro-menu-pending-clients' as MenuIcon,
      onPress: navigateWorkerScheduleSettings,
      show: isWorker && page.permissions.proProfiles.canEditOwnProfile(),
    },
    {
      title: t('personalServices'),
      icon: 'pro-menu-services' as MenuIcon,
      onPress: navigateWorkerServicesSettings,
      show: isWorker && page.permissions.proProfiles.canEditOwnProfile(),
    },
    {
      title: t('pros'),
      icon: 'pro-menu-team' as MenuIcon,
      onPress: navigateProsDashboard,
      show: page.permissions.proProfiles.canManageAllProfiles(),
    },
    {
      title: t('memberships'),
      icon: <ProMembershipMenuIcon />,
      onPress: navigatePageMembershipSettingsScreen,
      show: page.permissions.page.canManageMemberships(),
    },
    {
      title: t('products:productsTitle'),
      icon: <ProductsMenuIcon />,
      onPress: isPhone ? navigateProductMenu : navigateProductDashboard,
      show: page.permissions.products.canManageAll(),
    },
  ];
  const checkoutAndReportsSettings = [
    {
      show: page.permissions.page.canManageOnlinePayments(),
      title: t('onlinePayments'),
      icon: 'pro-menu-payments' as MenuIcon,
      onPress: navigatePageOnlinePaymentsSettings,
    },
    {
      show: page.permissions.page.canManageCheckout(),
      title: t('checkout'),
      icon: <ProCheckoutMenuIcon />,
      onPress: navigatePageCheckoutSettings,
    },
    {
      show: page.permissions.page.canManageGiftcards(),
      title: t('giftCards'),
      icon: 'pro-menu-giftcards' as MenuIcon,
      onPress: navigatePageGiftcardsSettings,
    },
    {
      show:
        (isWorker && page.permissions.statistics.canReadOwnStatistics()) ||
        page.permissions.statistics.canReadAllStatistics(),
      title: t('reports'),
      icon: 'pro-menu-reports' as MenuIcon,
      onPress: navigatePageReports,
    },
  ];

  const marketingSettings = [
    {
      show: page.details.published && page.permissions.marketing.canManageAll(),
      title: t('marketing'),
      icon: 'pro-menu-marketing' as MenuIcon,
      onPress: navigateMarketingSettings,
    },
    {
      show: page.permissions.reviews.canReadOwnReviews() || page.permissions.reviews.canReadAllReviews(),
      title: t('reviews'),
      icon: 'pro-menu-reviews' as MenuIcon,
      onPress: navigatePageReviews,
    },
    {
      show: page.permissions.page.canManageNotificationSettings(),
      title: t('smsMessages'),
      icon: 'pro-menu-client-notifications' as MenuIcon,
      onPress: navigatePageNotificationsSettings,
    },
    {
      show: page.permissions.page.canManageDetails(),
      title: t('pageDetails'),
      icon: 'pro-menu-page-details' as MenuIcon,
      onPress: navigatePageProfileSettings,
    },
  ];

  const otherSettings = [
    {
      show: page.permissions.account.canManageSubscription(),
      title: t('subscriptions'),
      onPress: navigatePageSubscriptionSettings,
    },
    {
      title: t('contact'),
      badge: unreadIntercomCount > 0 ? `${unreadIntercomCount}` : undefined,
      onPress: navigateContactSupport,
      show: true,
    },
    {
      show: appState.type === 'Loaded' && !!appState.featureFlags.barberStore?.enabled,
      title: t('buyProducts'),
      onPress: navigatePageShop,
    },
  ];
  const visibleGeneralSettings = generalSetting.filter((s) => s.show);
  const visibleCheckoutAndReportsSettings = checkoutAndReportsSettings.filter((s) => s.show);
  const visibleMarketingSettings = marketingSettings.filter((s) => s.show);
  const visibleOtherSettings = otherSettings.filter((s) => s.show);

  const GeneralSettings = () => (
    <MenuItemGroup fontSize={isDesktop ? 16 : 18} hasBorderRadius={isDesktop} options={visibleGeneralSettings} />
  );

  const CheckoutAndReportsSettings = () => (
    <MenuItemGroup
      fontSize={isDesktop ? 16 : 18}
      hasBorderRadius={isDesktop}
      options={visibleCheckoutAndReportsSettings}
    />
  );

  const MarketingSettings = () => (
    <MenuItemGroup fontSize={isDesktop ? 16 : 18} hasBorderRadius={isDesktop} options={visibleMarketingSettings} />
  );

  const OtherSettings = () => (
    <MenuItemGroup fontSize={isDesktop ? 16 : 18} hasBorderRadius={isDesktop} options={visibleOtherSettings} />
  );

  const PendingDeleteToast = () => (
    <Row
      style={{
        paddingVertical: 8,
        paddingHorizontal: 16,
        backgroundColor: '#F05F7C',
        borderRadius: 4,
        marginHorizontal: isPhone ? 16 : 0,
      }}
    >
      <SmallBody style={{ color: colors.WHITE, flex: 1 }}>{t('pendingDeletion')}</SmallBody>
      <TouchableOpacity style={{ paddingLeft: 4 }} onPress={clearPendingDelete}>
        <CloseIcon />
      </TouchableOpacity>
    </Row>
  );

  const UnlistedProfileToast = () => (
    <TouchableOpacity onPress={navigatePageOnlineProfileSettings}>
      <Row
        style={{
          paddingVertical: 8,
          paddingHorizontal: 16,
          backgroundColor: colors.YELLOW_ORANGE,
          borderRadius: 4,
          marginHorizontal: isPhone ? 16 : 0,
          alignItems: 'center',
        }}
      >
        <WarningIcon />
        <HSpacer left={8} />
        <SmallBody style={{ color: colors.WHITE, flex: 1 }}>
          {page.details.searchableRequested ? t('searchableRequested') : t('unlistedProfile')}
        </SmallBody>
        <Icon type="next" size={sizes[24]} color={colors.WHITE} />
      </Row>
    </TouchableOpacity>
  );

  const ToastSection = () =>
    !page.details.isSearchable || isPendingDelete ? (
      <>
        <Column>
          {isPendingDelete && (
            <>
              <PendingDeleteToast />
              <Spacer size={16} />
            </>
          )}
          {(!page.details.isSearchable || page.details.searchableRequested) && page.details.published && (
            <>
              <Spacer size={4} />
              <UnlistedProfileToast />
              <Spacer size={16} />
            </>
          )}
        </Column>
      </>
    ) : (
      <></>
    );

  return (
    <>
      <SafeAreaView style={{ flex: 1, backgroundColor: colors.ALABASTER }} edges={['top']}>
        {isPhone ? (
          <ScrollView style={styles.container}>
            <View style={styles.profileContainer}>
              <TouchableOpacity
                onPress={() => setShowProfileMobileMenu(true)}
                style={{ flexDirection: 'row', alignItems: 'center' }}
              >
                <Avatar
                  source={profilePhoto?.thumbnail}
                  firstname={selfMember?.user.profile.firstname ?? ''}
                  lastname={selfMember?.user.profile.lastname ?? ''}
                  size={24}
                />

                <HSpacer left={8} />
                <SmallBody style={meroStyles.text.semibold} numberOfLines={1}>
                  {nameGenerator(selfMember?.user.profile ?? {}, 'Fără nume')}
                </SmallBody>
                <HSpacer left={4} />
                <DownArrowSmallIcon />
              </TouchableOpacity>

              <Spacer size={32} />
              <Avatar source={page.details.profilePhoto.small} firstname={page.details.name} lastname="" size={90} />

              <Spacer size="16" />
              <TouchableOpacity onPress={navigateSelectPage}>
                <Row>
                  <H1 style={{ maxWidth: 320, textAlign: 'center' }}>{page.details.name}</H1>
                  <Row style={{ paddingTop: 4 }}>
                    <DownArrowMediumIcon />
                  </Row>
                </Row>
              </TouchableOpacity>

              {hasEnoughReviews && (
                <Row style={[styles.reviewLabel, { paddingTop: 8 }]}>
                  <StarSmallIcon />
                  <HSpacer left={2} />
                  <Row style={{ alignItems: 'center' }}>
                    <SmallBody style={[meroStyles.text.semibold]}>{page.details.feedback.score.toFixed(2)}</SmallBody>
                    <TouchableOpacity onPress={navigatePageReviews} style={{ marginLeft: 6 }}>
                      <SmallBody style={[meroStyles.text.link]}>
                        {t('reviews:totalReviews', { count: page.details.feedback.total })}
                      </SmallBody>
                    </TouchableOpacity>
                  </Row>
                </Row>
              )}

              {page.details.published && (
                <>
                  <Spacer size={16} />
                  <Row style={{ justifyContent: 'space-between' }}>
                    <TouchableOpacity style={[styles.iconButton, { width: 164 }]} onPress={openPageUrl}>
                      <OrionEyeIcon />
                      <HSpacer left={4} />
                      <Body style={[meroStyles.text.semibold, { fontSize: 13 }]}>{t('viewProfile')}</Body>
                    </TouchableOpacity>
                    <HSpacer left={15} />
                    <TouchableOpacity style={[styles.iconButton, { width: 164 }]} onPress={navigateSharePageProfile}>
                      <QRCodeIcon />
                      <HSpacer left={4} />
                      <Body style={[meroStyles.text.semibold, { fontSize: 13 }]}>{t('shareProfile')}</Body>
                    </TouchableOpacity>
                  </Row>
                </>
              )}
            </View>

            <ToastSection />

            {visibleGeneralSettings.length !== 0 && (
              <>
                <Spacer size={8} />
                <SmallBody style={[styles.subtitle, { paddingLeft: 24 }]}>{t('generalSettings')}</SmallBody>
                <GeneralSettings />
              </>
            )}

            {visibleMarketingSettings.length !== 0 && (
              <>
                <Spacer size="32" color={colors.ALABASTER} />
                <SmallBody style={[styles.subtitle, { paddingLeft: 24 }]}>{t('marketingSettings')}</SmallBody>
                <MarketingSettings />
              </>
            )}

            {visibleCheckoutAndReportsSettings.length !== 0 && (
              <>
                <Spacer size="32" color={colors.ALABASTER} />
                <SmallBody style={[styles.subtitle, { paddingLeft: 24 }]}>{t('checkoutAndReportsSettings')}</SmallBody>
                <CheckoutAndReportsSettings />
              </>
            )}

            {visibleOtherSettings.length !== 0 && (
              <>
                <Spacer size="32" color={colors.ALABASTER} />
                <SmallBody style={[styles.subtitle, { paddingLeft: 24 }]}>{t('otherSettings')}</SmallBody>
                <OtherSettings />
              </>
            )}

            <Spacer size={64} color={colors.ALABASTER} />
          </ScrollView>
        ) : (
          <>
            <Row
              alignItems="center"
              justifyContent="space-between"
              style={[styles.headerWrapperWithShadow, { paddingHorizontal: 24, paddingVertical: 20 }]}
            >
              <Row style={{ alignItems: hasEnoughReviews ? 'flex-start' : 'center' }}>
                <View style={{ paddingTop: 4 }}>
                  <Avatar
                    source={page.details.profilePhoto.medium}
                    firstname={page.details.name}
                    lastname=""
                    size={50}
                  />
                </View>
                <HSpacer left={12} />
                <Column style={{ alignItems: 'flex-start' }}>
                  <TouchableOpacity
                    onPress={navigateSelectPage}
                    style={{ flexDirection: 'row', alignItems: 'flex-start' }}
                  >
                    <H1 style={{ maxWidth: 400 }}>{page.details.name}</H1>
                    <Row style={{ paddingTop: 4 }}>
                      <DownArrowMediumIcon />
                    </Row>
                  </TouchableOpacity>

                  {hasEnoughReviews && (
                    <Row style={{ alignItems: 'center' }}>
                      <StarIcon />
                      <HSpacer left={2} />
                      <Row style={{ alignItems: 'center' }}>
                        <SmallBody style={[meroStyles.text.semibold]}>
                          {page.details.feedback.score.toFixed(2)}
                        </SmallBody>
                        <TouchableOpacity onPress={navigatePageReviews} style={{ marginLeft: 6 }}>
                          <SmallBody style={[meroStyles.text.link]}>
                            {t('reviews:totalReviews', { count: page.details.feedback.total })}
                          </SmallBody>
                        </TouchableOpacity>
                      </Row>
                    </Row>
                  )}
                </Column>
              </Row>

              <Row style={{ alignItems: 'center' }}>
                {page.details.published && (
                  <>
                    <TouchableOpacity style={[styles.iconButton]} onPress={openPageUrl}>
                      <OrionEyeIcon />
                      <HSpacer left={4} />
                      <Body style={[meroStyles.text.semibold, { fontSize: 13 }]}>{t('viewProfile')}</Body>
                    </TouchableOpacity>
                    <HSpacer left={24} />
                    <TouchableOpacity style={[styles.iconButton]} onPress={navigateSharePageProfile}>
                      <QRCodeIcon />
                      <HSpacer left={4} />
                      <Body style={[meroStyles.text.semibold, { fontSize: 13 }]}>{t('shareProfile')}</Body>
                    </TouchableOpacity>
                  </>
                )}

                <HSpacer left={24} />
                <View ref={profileButtonsRef}>
                  <TouchableOpacity
                    onPress={() => setShowProfileWebMenu((prev) => !prev)}
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                  >
                    <Avatar
                      source={profilePhoto?.thumbnail}
                      firstname={selfMember?.user.profile.firstname ?? ''}
                      lastname={selfMember?.user.profile.lastname ?? ''}
                      size={24}
                    />

                    <HSpacer left={8} />
                    <SmallBody style={meroStyles.text.semibold} numberOfLines={1}>
                      {nameGenerator(selfMember?.user.profile ?? {}, 'Fără nume')}
                    </SmallBody>
                    <HSpacer left={4} />
                    <DownArrowSmallIcon />
                  </TouchableOpacity>
                  <Column style={{ position: 'relative' }}>
                    {showProfileWebMenu && isDesktop && (
                      <ProfileMenuWeb
                        onClose={() => setShowProfileWebMenu(false)}
                        navigateHelpCenter={navigateHelpCenter}
                        navigateNotificationsOptions={navigateNotificationsOptions}
                        navigateSelectPage={navigateSelectPage}
                        navigateWorkerProfileSettings={navigateWorkerProfileSettings}
                      />
                    )}
                  </Column>
                </View>
              </Row>
            </Row>

            <ScrollView style={styles.container}>
              <Row style={{ padding: 24, flex: 1, maxWidth: 964 }}>
                <Column style={{ flex: 1, width: '50%' }}>
                  <ToastSection />

                  {visibleGeneralSettings.length !== 0 && (
                    <>
                      <SmallBody style={[styles.subtitle, { fontSize: 12 }]}>{t('generalSettings')}</SmallBody>
                      <GeneralSettings />
                    </>
                  )}

                  {visibleMarketingSettings.length !== 0 && (
                    <>
                      <Spacer size="24" color={colors.ALABASTER} />
                      <SmallBody style={[styles.subtitle, { fontSize: 12 }]}>{t('marketingSettings')}</SmallBody>
                      <MarketingSettings />
                    </>
                  )}
                </Column>
                <HSpacer left={32} />
                <Column style={{ flex: 1, width: '50%' }}>
                  {visibleCheckoutAndReportsSettings.length !== 0 && (
                    <>
                      <SmallBody style={[styles.subtitle, { fontSize: 12 }]}>
                        {t('checkoutAndReportsSettings')}
                      </SmallBody>
                      <CheckoutAndReportsSettings />
                    </>
                  )}

                  {visibleOtherSettings.length !== 0 && (
                    <>
                      {visibleCheckoutAndReportsSettings.length !== 0 && <Spacer size="24" color={colors.ALABASTER} />}
                      <SmallBody style={[styles.subtitle, { fontSize: 12 }]}>{t('otherSettings')}</SmallBody>
                      <OtherSettings />
                    </>
                  )}
                </Column>
              </Row>
            </ScrollView>
          </>
        )}
      </SafeAreaView>

      {showProfileMobileMenu && (
        <ProfileMenuMobile
          page={page}
          onClose={() => setShowProfileMobileMenu(false)}
          navigateHelpCenter={navigateHelpCenter}
          navigateNotificationsOptions={navigateNotificationsOptions}
          navigateSelectPage={navigateSelectPage}
          navigateWorkerProfileSettings={navigateWorkerProfileSettings}
        />
      )}
    </>
  );
};

export const MenuScreenBackNavigationContext = backNavigationContext();

export default pipe(MenuScreenComponent, Authorized, CurrentBusiness);
