import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { ColorSchemeName, Platform, AppState, AppStateStatus } from 'react-native';

import ChatRedirectScreen from '../screens/ChatRedirectScreen';
import ImpersonateScreen from '../screens/ImpersonateScreen';
import IndexScreen from '../screens/IndexScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import PrivacyPolicyScreen from '../screens/PrivacyPolicyScreen';
import SplashScreen from '../screens/SplashScreen';
import TermsOfServiceScreen from '../screens/TermsOfServiceScreen';

import ContainerWithBackground from '../components/ContainerWithBackground';

import LinkingConfiguration from '../navigation/LinkingConfiguration';
import { NavigationContainer, DefaultTheme, DarkTheme, NavigationContainerRef } from '@react-navigation/native';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { googleAnalytics } from '../analytics/googleAnalytics';
import { withAppEventsContextProvider } from '../contexts/AppEvents';
import { AuthContext, AuthInitFailedScreen, withAuthContextProvider } from '../contexts/AuthContext';
import { handlePushMessage as handleIntercomPushMessage } from '../contexts/IntercomContext/utils';
import { NavigationContext } from '../contexts/NavigationContext';
import { withPushClientSubscriptionContextProvider } from '../contexts/PushClientSubscriptionContext';
import { withPushServerSubscriptionContextProvider } from '../contexts/PushServerSubscriptionContext';
import { RootStackParamList } from '../types';
import AnonymousStackNavigator from './AnonymousStackNavigator';
import AuthorizedStackNavigator from './AuthorizedStackNavigator';
import { styles } from './styles';

const MeroDefaultTheme: typeof DefaultTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#080DE0',
    text: '#172B4D',
    notification: '#080DE0',
    background: 'transparent',
  },
};

const MeroDarkTheme: typeof DarkTheme = DarkTheme;

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const RootStack = createStackNavigator<RootStackParamList>();

const RootNavigator: React.FC = () => {
  const { isPhone } = useMediaQueries();
  const [authState] = AuthContext.useContext();

  const signInModalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopSignInModalCard
    : styles.tabletSignInModalCard;

  React.useEffect(() => {
    const listener = (nextAppState: AppStateStatus): void => {
      nextAppState === 'active' && handleIntercomPushMessage();
    };

    const event = AppState.addEventListener('change', listener);

    return () => event.remove();
  }, []);

  React.useEffect(() => {
    if (authState.type === 'Authorized') {
      googleAnalytics.init();
    }
  }, [authState.type]);

  if (authState.type !== 'Authorized' && authState.type !== 'Anonymous') {
    if (authState.type === 'Failed') {
      return <AuthInitFailedScreen />;
    } else {
      return <SplashScreen />;
    }
  }

  return (
    <ContainerWithBackground>
      <RootStack.Navigator
        screenOptions={{ headerShown: false, presentation: 'transparentModal' }}
        initialRouteName={authState.type === 'Authorized' ? 'Authorized' : 'Anonymous'}
      >
        <RootStack.Screen name="Index" component={IndexScreen} />
        <RootStack.Screen name="Open" component={IndexScreen} />
        {authState.type === 'Authorized' ? (
          <>
            <RootStack.Screen name="Authorized" component={AuthorizedStackNavigator} />
          </>
        ) : (
          <>
            <RootStack.Screen name="Anonymous" component={AnonymousStackNavigator} />
          </>
        )}
        <RootStack.Group screenOptions={{ cardStyle: signInModalStyle }}>
          <RootStack.Screen
            name="TermsOfServiceScreen"
            component={TermsOfServiceScreen}
            options={{ title: 'Termeni și condiții Utilizatori Tip Profesionist MERO' }}
          />
          <RootStack.Screen
            name="PrivacyPolicyScreen"
            component={PrivacyPolicyScreen}
            options={{ title: 'Politica de confidențialitate' }}
          />
        </RootStack.Group>
        <RootStack.Screen
          name="ImpersonateScreen"
          component={ImpersonateScreen}
          options={{
            ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalFadeTransition),
            title: 'Autorizare implicită',
          }}
        />
        <RootStack.Screen name="ManagementRedirectScreen" component={IndexScreen} />
        <RootStack.Screen name="ManagementChildRedirectScreen" component={IndexScreen} />
        <RootStack.Screen name="ChatRedirectScreen" component={ChatRedirectScreen} />
        <RootStack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      </RootStack.Navigator>
    </ContainerWithBackground>
  );
};

type Props = {
  readonly colorScheme: ColorSchemeName;
};

const Navigation: React.FC<Props> = ({ colorScheme }) => {
  const navigationRef = React.useRef<NavigationContainerRef<RootStackParamList>>(null);
  const [, { addWebPath }] = NavigationContext.useContext();

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? MeroDarkTheme : MeroDefaultTheme}
      ref={navigationRef}
      onReady={() => {
        if (Platform.OS === 'web') {
          addWebPath();
        }
      }}
      onStateChange={async () => {
        if (Platform.OS === 'web') {
          addWebPath();
        }
      }}
    >
      <RootNavigator />
    </NavigationContainer>
  );
};

export default pipe(
  Navigation,
  withPushServerSubscriptionContextProvider,
  withPushClientSubscriptionContextProvider,
  withAppEventsContextProvider,
  withAuthContextProvider,
);
