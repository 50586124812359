import { None, PageOnlinePaymentsSettings, PortionPercentScaledNumber, ScaledNumber } from '@mero/api-sdk';
import {
  Body,
  Button,
  Checkbox,
  Column,
  FormCard,
  H1,
  H3s,
  Header,
  Icon,
  InputWithLabel,
  Line,
  Row,
  SafeAreaView,
  Select,
  SmallBody,
  Spacer,
  Title,
  colors,
  useShowError,
  useToast,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { OnlinePaymentsContext } from '../../../../../contexts/OnlinePaymentsContext';
import { PageOnlinePaymentsSettingsStackParamList } from '../../../../../types';
import { styles } from './PageOnlinePaymentsAdvancedPayment.styles';

export const generatePercentageWithStep = (step = 5) => {
  return Array(Math.ceil(100 / step))
    .fill(0)
    .map((_, index) => ((index + 1) * step > 100 ? 100 : (index + 1) * step))
    .map((v) => ({ value: v, label: `${v}%` }));
};

export type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<PageOnlinePaymentsSettingsStackParamList, 'PageOnlinePaymentsAdvancedPayment'>;

const PageOnlinePaymentsAdvancedPayment: React.FC<Props> = ({ page, navigation }) => {
  const { t } = useTranslation('onlinePayments');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();
  const toast = useToast();
  const showError = useShowError();

  const [onlinePaymentsState, { reloadAsync }] = OnlinePaymentsContext.useContext();

  const [advancedPaymentType, setAdvancedPaymentType] = React.useState<'Off' | 'AllServices' | 'SpecificServices'>(
    onlinePaymentsState.settings?.requireAdvancePayment.type ?? 'Off',
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const [allPercentage, setAllPercentage] = React.useState(
    onlinePaymentsState.settings?.requireAdvancePayment.type === 'AllServices'
      ? ScaledNumber.toNumber(onlinePaymentsState.settings.requireAdvancePayment.minPercent)
      : 5,
  );

  const save = async () => {
    setIsLoading(true);
    try {
      // const settings = advancedPaymentType === 'Off' ? { type: 'Off' } : undefined;
      const requireAdvancePayment = (
        advancedPaymentType === 'Off'
          ? { type: 'Off' }
          : advancedPaymentType === 'AllServices'
          ? {
              type: 'AllServices',
              minPercent: ScaledNumber.fromNumber(allPercentage, 2) as PortionPercentScaledNumber,
              applyToCustomers:
                onlinePaymentsState.settings && onlinePaymentsState.settings.requireAdvancePayment.type !== 'Off'
                  ? onlinePaymentsState.settings.requireAdvancePayment.applyToCustomers
                  : 'All',
            }
          : {
              type: 'SpecificServices',
              applyToCustomers:
                onlinePaymentsState.settings && onlinePaymentsState.settings.requireAdvancePayment.type !== 'Off'
                  ? onlinePaymentsState.settings.requireAdvancePayment.applyToCustomers
                  : 'All',
              servicesMap: onlinePaymentsState.selectedServices,
            }
      ) satisfies PageOnlinePaymentsSettings['requireAdvancePayment'];

      await meroApi.pages.updatePageOnlinePaymentsSettings({
        pageId: page.details._id,
        settings: {
          // TODO: set the right value for appointmentPayments when UI is in place
          appointmentPayments: None,
          requireAdvancePayment,
          advancePaymentTos: onlinePaymentsState.settings?.advancePaymentTos,
        },
      });
      await reloadAsync({ pageId: page.details._id });
      toast.show({ type: 'success', text: t('changesSaved') });
      goBack();
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('advancedPayment')}
      />
      <ScrollView style={{ flex: 1, paddingHorizontal: 16 }}>
        <Spacer size={16} />
        <H1 style={{ paddingHorizontal: 8 }}>{t('advancedPayment')}</H1>
        <Spacer size={8} />
        <Body style={{ paddingHorizontal: 8 }}>{t('advancedPaymentDescription')}</Body>
        <Spacer size={24} />
        <FormCard paddings="none" rounded dropShaddow style={{ paddingVertical: 24 }}>
          <TouchableOpacity
            style={{ paddingHorizontal: 16, flexDirection: 'row' }}
            onPress={() => setAdvancedPaymentType('Off')}
          >
            <Checkbox value={advancedPaymentType === 'Off'} onValueChange={() => setAdvancedPaymentType('Off')} />
            <Column style={{ paddingLeft: 12, flex: 1 }}>
              <H3s>{t('off')}</H3s>
              <Spacer size={4} />
              <SmallBody>{t('offDescription')}</SmallBody>
            </Column>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity
            style={{ paddingHorizontal: 16, flexDirection: 'row' }}
            onPress={() => setAdvancedPaymentType('AllServices')}
          >
            <Checkbox
              value={advancedPaymentType === 'AllServices'}
              onValueChange={() => setAdvancedPaymentType('AllServices')}
            />
            <Column style={{ paddingLeft: 12, flex: 1 }}>
              <H3s>{t('allServices')}</H3s>
              <Spacer size={4} />
              <SmallBody>{t('allServicesDescription')}</SmallBody>
              <Spacer size={12} />
              <InputWithLabel label={t('minPercentage')}>
                <Select
                  editable={advancedPaymentType === 'AllServices'}
                  value={allPercentage}
                  onChange={setAllPercentage}
                  items={generatePercentageWithStep()}
                />
              </InputWithLabel>
            </Column>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity
            style={{ paddingHorizontal: 16, flexDirection: 'row' }}
            onPress={() => setAdvancedPaymentType('SpecificServices')}
          >
            <Checkbox
              value={advancedPaymentType === 'SpecificServices'}
              onValueChange={() => setAdvancedPaymentType('SpecificServices')}
            />
            <Column style={{ paddingLeft: 12, flex: 1 }}>
              <H3s>{t('specificServices')}</H3s>
              <Spacer size={4} />
              <SmallBody>{t('specificServicesDescription')}</SmallBody>
              <Spacer size={12} />
              <TouchableOpacity
                style={{
                  paddingVertical: 12,
                  paddingLeft: 14,
                  paddingRight: 8,
                  borderRadius: 6,
                  borderWidth: 1,
                  borderColor: colors.ATHENS_GRAY,
                }}
                disabled={advancedPaymentType !== 'SpecificServices'}
                onPress={() => {
                  navigation.navigate('PageOnlinePaymentsServiceSettings');
                }}
              >
                <Row>
                  <Title style={{ flex: 1 }}>{t('servicesWithAdvanced')}</Title>
                  <Icon type="next" color={colors.DARK_BLUE} />
                </Row>
                <SmallBody style={{ color: colors.COMET }}>
                  {t('servicesWithAdvancedDescription', {
                    value: onlinePaymentsState.selectedServices.length,
                    total: page.details.services.length,
                  })}
                </SmallBody>
              </TouchableOpacity>
            </Column>
          </TouchableOpacity>
        </FormCard>
        <Spacer size={96} />
      </ScrollView>

      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('saveChanges')} onClick={save} disabled={isLoading} />
          ) : (
            <Button
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onClick={save}
              disabled={isLoading}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default pipe(PageOnlinePaymentsAdvancedPayment, CurrentBusiness, Authorized);
